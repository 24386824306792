@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Syne&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  font-family: "Open Sans", "Syne", sans-serif;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
  color: black;
}

.main__container {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Syne", sans-serif;
}

p {
  font-family: "Open Sans", sans-serif;
}

.nav__container {
  font-family: "Open Sans", sans-serif;
  background-color: #000000;
  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 12vh;
  padding: 0 15rem;
  text-transform: uppercase;
  font-size: 15px;
}
.nav__container h2 {
  font-size: 15px;
  letter-spacing: 1px;
}
.nav__container button {
  display: none;
}
.nav__container ul {
  display: flex;
  list-style: none;
  font-family: "syne", sans-serif;
}
.nav__container ul li {
  padding: 0 2rem;
}
.nav__container ul #portfolio {
  color: #fd5226;
}
.nav__container ul #portfolio:hover {
  font-weight: 700;
}
.nav__container ul .link {
  color: #f2f2f2;
  text-decoration: none;
  transition: color 0.3s, box-shadow 0.3s;
}
.nav__container ul .link:hover {
  color: #fd5226;
}

.input-fields, .contact__container .contact__form textarea, .contact__container .contact__form input {
  margin-bottom: 1rem;
  border: 3px solid #000000;
  padding-left: 10px;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  font-weight: 700;
  letter-spacing: 1.2px;
}

.contact__container {
  height: 88vh;
  width: 100%;
  padding: 15rem;
  padding-top: 14rem;
  background-color: #f2f2f2;
  color: #000000;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 2fr 1fr;
}
.contact__container .contact__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact__container .contact__info h2 {
  font-size: 3.5rem;
}
.contact__container .contact__info h3 {
  font-size: 1.4rem;
}
.contact__container .contact__info span {
  color: #fd5226;
  margin-right: 5px;
}
.contact__container .contact__form form {
  display: flex;
  flex-direction: column;
}
.contact__container .contact__form input {
  height: 3rem;
}
.contact__container .contact__form textarea {
  height: 10rem;
  padding-top: 10px;
}
.contact__container .contact__form *::-moz-placeholder {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.contact__container .contact__form *::placeholder {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.contact__container .contact__form button {
  width: 9rem;
  height: 3.5rem;
  color: #000000;
  font-size: 1.2rem;
  background-color: #fd5226;
  border: 2px solid #fd5226;
  border: none;
}
.contact__container .contact__form button:hover {
  border: 2px solid #fd5226;
  cursor: pointer;
  color: #fd5226;
  background-color: #f2f2f2;
}
.contact__container .contact__developed {
  height: 5rem;
  margin-top: 10rem;
  border-top: 1px solid #000000;
  padding-top: 1rem;
  grid-column: 1/3;
  display: flex;
  justify-content: space-between;
}
.contact__container .contact__developed h4 {
  color: #141414;
}
.contact__container .contact__developed h4 span {
  color: #fd5226;
}
.contact__container .contact__developed ul {
  display: flex;
  list-style: none;
  width: 480px;
}
.contact__container .contact__developed li {
  margin: 0 2rem;
}
.contact__container .contact__developed li a {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
}
.contact__container .contact__developed li button {
  background-color: transparent;
  border: none;
  font-size: 1.4rem;
  color: #000000;
  margin-right: 5px;
}
.contact__container .contact__developed li:first-child button:hover {
  color: #ba55d3;
}
.contact__container .contact__developed li:nth-child(2) button:hover {
  color: #0000ff;
}
.contact__container .contact__developed li:last-child button:hover {
  color: #ff8c69;
}
.contact__container .contact__developed li button:hover {
  cursor: pointer;
  color: #fd5226;
}

.header__container {
  background-color: #000000;
  color: #f2f2f2;
  height: 36rem;
  width: 100%;
  padding: 2rem 15rem;
}
.header__container h2 {
  font-size: 70px;
  margin-bottom: 4rem;
}
.header__container .header-link {
  background-color: #fd5226;
  width: 185px;
  height: 52px;
  padding: 15px 30px;
  font-size: 18px;
  text-decoration: none;
  color: #000000;
  text-transform: uppercase;
  font-family: "Syne";
}
.header__container .header-link:hover {
  background-color: #000000;
  border: 2px solid #fd5226;
  cursor: pointer;
  color: #fd5226;
}

.profile {
  width: 100%;
  height: 50rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5rem 15rem;
  background-color: #f2f2f2;
}
.profile .profile__text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.profile .profile__text h2 {
  font-size: 50px;
  width: 90%;
}
.profile .profile__text p {
  font-size: 15px;
  width: 82%;
}
.profile .profile__text .profile-link {
  width: 205px;
  height: 52px;
  font-size: 18px;
  text-align: center;
  background-color: #fd5226;
  text-decoration: none;
  color: #000000;
  text-transform: uppercase;
  padding: 15px 30px;
}
.profile .profile__text .profile-link:hover {
  border: 2px solid #fd5226;
  background-color: #f2f2f2;
  color: #fd5226;
}
.profile .profile__img {
  background-image: url(./assets/profile-img.svg);
  background-position: center 11rem;
  background-repeat: no-repeat;
}

.projects__general, .banner .banner__project-6th, .banner .banner__project-5th, .banner .banner__project-4th, .banner .banner__project-3rd, .banner .banner__project-2nd, .banner .banner__project-1st {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.banner {
  width: 100%;
  height: 50rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1.49fr 1.49fr;
  grid-template-rows: 1fr 1fr;
  background-color: #000000;
}
.banner .banner__project-1st {
  background-image: url(./assets/momentum.png);
  grid-row: 1/2;
  grid-column: 1/3;
}
.banner .banner__project-2nd {
  background-image: url(./assets/weather.png);
  grid-row: 1/3;
  grid-column: 3/4;
}
.banner .banner__project-3rd {
  background-image: url(./assets/todo.png);
  grid-row: 1/2;
  grid-column: 4/5;
}
.banner .banner__project-4th {
  background-image: url(./assets/museum.png);
  grid-row: 2/3;
  grid-column: 1/2;
}
.banner .banner__project-5th {
  background-image: url(./assets/portfolio.png);
  grid-row: 2/3;
  grid-column: 2/3;
}
.banner .banner__project-6th {
  background-image: url(./assets/sunny.png);
  grid-row: 2/3;
  grid-column: 4/5;
}

.skills {
  width: 100%;
  height: 44rem;
  padding: 2rem 15rem;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: "Syne";
}
.skills h2 {
  text-align: center;
  font-size: 50px;
  margin-bottom: 1rem;
  color: #f2f2f2;
}
.skills .skills__grid {
  display: grid;
  grid-template-columns: repeat(4, 1.2fr);
  grid-template-rows: repeat(4, 1fr);
}
.skills .skills__grid .skills__box {
  border: 2px solid #fd5226;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
}
.skills .skills__grid .skills__box img {
  width: 50px;
  height: 50px;
}
.skills .skills__grid .skills__box h3 {
  color: #f2f2f2;
}
.skills .skills__grid .skills__box:first-child {
  border-top: none;
  border-left: none;
}
.skills .skills__grid .skills__box:nth-child(2),
.skills .skills__grid .skills__box:nth-child(3) {
  border-top: none;
}
.skills .skills__grid .skills__box:nth-child(4) {
  border-top: none;
  border-right: none;
}
.skills .skills__grid .skills__box:nth-child(5),
.skills .skills__grid .skills__box:nth-child(9) {
  border-left: none;
}
.skills .skills__grid .skills__box:nth-child(8),
.skills .skills__grid .skills__box:nth-child(12) {
  border-right: none;
}
.skills .skills__grid .skills__box:nth-child(13) {
  border-bottom: none;
  border-left: none;
}
.skills .skills__grid .skills__box:nth-child(14),
.skills .skills__grid .skills__box:nth-child(15) {
  border-bottom: none;
}
.skills .skills__grid .skills__box:nth-child(16) {
  border-right: none;
  bottom: none;
}

.portfolio__container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 15rem 100px;
  text-align: center;
  background-color: #000000;
}
.portfolio__container h2 {
  color: #f2f2f2;
  font-size: 75px;
  font-family: "syne";
  padding: 80px 0;
}
.portfolio__container .portfolio__buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}
.portfolio__container .portfolio__buttons button {
  font-size: 15px;
  font-family: "Syne";
  margin: 5px;
  padding: 8px 20px;
  height: 40px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0;
  border: none;
  background-color: #f2f2f2;
  cursor: pointer;
  text-transform: uppercase;
}
.portfolio__container .portfolio__buttons button:hover {
  background-color: #fd5226;
}
.portfolio__container .portfolio__buttons button:first-child {
  background-color: #fd5226;
}
.portfolio__container .portfolio__buttons button:active {
  background-color: yellow;
}
.portfolio__container .portfolio__projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 470px);
  gap: 0.8rem;
}
.portfolio__container .portfolio__projects .portfolio__project-box {
  border: 2px solid red;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 2rem;
  padding: 0.8rem;
  border: 2px solid #fd5226;
}
.portfolio__container .portfolio__projects .portfolio__project-box .project__preview {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 133px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.portfolio__container .portfolio__projects .portfolio__project-box .project__preview img {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.3s ease-in-out;
}
.portfolio__container .portfolio__projects .portfolio__project-box .project__preview img:hover {
  transform: scale(1.1);
}
.portfolio__container .portfolio__projects .portfolio__project-box .project__title {
  font-size: 20px;
  color: #f2f2f2;
}
.portfolio__container .portfolio__projects .portfolio__project-box p {
  color: #f2f2f2;
}
.portfolio__container .portfolio__projects .portfolio__project-box .portfolio__icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.portfolio__container .portfolio__projects .portfolio__project-box .portfolio__icons a {
  text-decoration: none;
  margin: auto 0;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #f2f2f2;
  border: none;
  background-color: transparent;
  width: 20%;
}
.portfolio__container .portfolio__projects .portfolio__project-box .portfolio__icons a button {
  background-color: transparent;
  border: none;
}
.portfolio__container .portfolio__projects .portfolio__project-box .portfolio__icons a h3 {
  margin-left: 5px;
}
.portfolio__container .portfolio__projects .portfolio__project-box .portfolio__icons a #explorer {
  color: #1e90ff;
}
.portfolio__container .portfolio__projects .portfolio__project-box .portfolio__icons a #github {
  color: #ba55d3;
}
.portfolio__container .portfolio__projects .portfolio__project-box .portfolio__icons a:first-child h3:hover {
  color: #1e90ff;
}
.portfolio__container .portfolio__projects .portfolio__project-box .portfolio__icons a:nth-child(2) h3:hover {
  color: #ba55d3;
}
.portfolio__container .portfolio__projects .portfolio__project-box .Momentum-clone {
  grid-row: 1/2;
  grid-column: 1/2;
}
.portfolio__container .portfolio__projects .portfolio__project-box .Todo {
  grid-row: 1/2;
  grid-column: 2/3;
}
.portfolio__container .portfolio__projects .portfolio__project-box .Sunny {
  grid-row: 1/2;
  grid-column: 3/4;
}
.portfolio__container .portfolio__projects .portfolio__project-box .Weather {
  grid-row: 2/4;
  grid-column: 1/2;
}
.portfolio__container .portfolio__projects .portfolio__project-box .Portfolio {
  grid-row: 2/4;
  grid-column: 3/4;
}
.portfolio__container .portfolio__projects .portfolio__project-box .Calculator {
  grid-row: 3/4;
  grid-column: 2/3;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dropdown-box {
  width: 30%;
  background: #f2f2f2;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: dropDown 0.3s ease-out;
}
.dropdown-box .dropdown-close {
  display: flex;
  justify-content: end;
}
.dropdown-box .dropdown-close button {
  color: #000000;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 8px 14px;
  border-radius: 8px;
}
.dropdown-box .dropdown-close button:hover {
  background: #fd5226;
}
.dropdown-box a {
  text-decoration: none;
  background-color: #fd5226;
  color: #141414;
  border-radius: 8px;
  padding: 5px;
}

.dropdown-box p {
  font-weight: 500;
  margin: 0 0 25px;
  color: #141414;
}

@keyframes dropDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (max-width: 480px) {
  .nav__container {
    padding: 0px;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 5px;
  }
  .nav__container .nav__text {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .nav__container .nav__text button {
    border: none;
    font-size: 20px;
    background: #f2f2f2;
    color: #000000;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav__container .showNav-active {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
  }
  .nav__container .showNav-active li {
    border-top: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
    padding: 20px;
    font-size: 15px;
    min-height: 80px;
    display: flex;
    align-items: center;
  }
  .nav__container .showNav-disable {
    display: none;
  }
  .header__container {
    padding: 40px 20px 100px;
    height: 360px;
  }
  .header__container h2 {
    font-size: 30px;
  }
  .header__container .header-link {
    width: 144px;
    height: 46px;
    padding: 12px 24px;
    font-weight: bold;
  }
  .profile {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    padding: 60px 20px 100px;
    height: 955px;
  }
  .profile .profile__img {
    background-position: center 5rem;
    grid-row: 1/2;
    background-size: 80%;
  }
  .profile .profile__text {
    grid-row: 2/3;
    text-align: center;
    margin: 0 auto;
  }
  .profile .profile__text h2 {
    font-size: 28px;
    width: 100%;
  }
  .profile .profile__text p {
    font-size: 15px;
    width: 100%;
  }
  .profile .profile__text .profile-link {
    width: 193px;
    height: 46px;
    padding: 12px 24px;
    margin: 0 auto;
  }
  .banner {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 200px);
    height: 1400px;
  }
  .banner .banner__project-1st {
    grid-row: 1/2;
    grid-column: 1/1;
  }
  .banner .banner__project-2nd {
    grid-row: 4/6;
    grid-column: 1/1;
  }
  .banner .banner__project-3rd {
    grid-row: 2/3;
    grid-column: 1/1;
  }
  .banner .banner__project-4th {
    grid-row: 3/4;
    grid-column: 1/1;
  }
  .banner .banner__project-5th {
    grid-row: 6/7;
    grid-column: 1/1;
    background-position: center 5px;
  }
  .banner .banner__project-6th {
    grid-row: 7/8;
    grid-column: 1/1;
  }
  .skills {
    padding: 100px 20px;
    height: 1040px;
  }
  .skills h2 {
    font-size: 28px;
  }
  .skills .skills__grid {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat();
  }
  .skills .skills__grid .skills__box:nth-child(2) {
    border-top: none;
    border-right: none;
  }
  .skills .skills__grid .skills__box:nth-child(even) {
    border-right: none;
  }
  .skills .skills__grid .skills__box:nth-child(odd) {
    border-left: none;
  }
  .contact__container {
    height: 800px;
    padding: 100px 20px 80px;
    grid-template-columns: 1fr;
    grid-template-rows: 290px 390px 100px;
  }
  .contact__container .contact__info {
    height: 290px;
  }
  .contact__container .contact__info h2 {
    font-size: 28px;
  }
  .contact__container .contact__info h3 {
    font-size: 18px;
  }
  .contact__container .contact__developed {
    margin-top: 20px;
  }
  .contact__container .contact__developed li {
    margin: 0 10px;
  }
  .portfolio__container {
    height: -moz-fit-content;
    height: fit-content;
    padding: 40px 20px 40px;
  }
  .portfolio__container h2 {
    font-size: 30px;
  }
  .portfolio__container .portfolio__projects {
    display: flex;
    flex-direction: column;
  }
  .portfolio__container .portfolio__projects .portfolio__project-box {
    height: -moz-fit-content;
    height: fit-content;
  }
  .portfolio__container .portfolio__buttons {
    padding: 40px 20px 40px;
    width: 370px;
    height: -moz-fit-content;
    height: fit-content;
    flex-wrap: wrap;
    margin-bottom: 80px;
  }
  .portfolio__container .portfolio__projects {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(8, 190px);
    gap: 25px;
    margin: 0 auto;
    width: 85%;
  }
  .contact__container {
    padding: 1rem 20px;
  }
  .contact__container .contact__info {
    grid-row: 1/2;
    grid-column: 1/3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .contact__container .contact__form {
    grid-row: 2/3;
    grid-column: 1/3;
  }
  .dropdown-box {
    width: 85%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .nav__container {
    padding: 0px;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 5px;
  }
  .nav__container .nav__text {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .nav__container .nav__text button {
    border: none;
    font-size: 20px;
    background: #f2f2f2;
    color: #000000;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav__container .showNav-active {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
  }
  .nav__container .showNav-active li {
    border-top: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
    padding: 20px;
    font-size: 15px;
    min-height: 80px;
    display: flex;
    align-items: center;
  }
  .nav__container .showNav-disable {
    display: none;
  }
  .header__container {
    padding: 40px 20px 100px;
    height: 450px;
  }
  .header__container h2 {
    font-size: 50px;
  }
  .header__container .header-link {
    width: 144px;
    height: 46px;
    padding: 12px 24px;
    font-weight: bold;
  }
  .profile {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    padding: 60px 20px 100px;
    height: 955px;
  }
  .profile .profile__img {
    background-position: center 5rem;
    grid-row: 1/2;
    background-size: 80%;
  }
  .profile .profile__text {
    grid-row: 2/3;
    text-align: center;
    margin: 0 auto;
  }
  .profile .profile__text h2 {
    font-size: 42px;
    width: 100%;
  }
  .profile .profile__text p {
    font-size: 15px;
    width: 100%;
  }
  .profile .profile__text .profile-link {
    width: 193px;
    height: 46px;
    padding: 12px 24px;
    margin: 0 auto;
  }
  .banner {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 200px);
    height: 1400px;
  }
  .banner .banner__project-1st {
    grid-row: 1/2;
    grid-column: 1/1;
  }
  .banner .banner__project-2nd {
    grid-row: 4/6;
    grid-column: 1/1;
  }
  .banner .banner__project-3rd {
    grid-row: 2/3;
    grid-column: 1/1;
  }
  .banner .banner__project-4th {
    grid-row: 3/4;
    grid-column: 1/1;
  }
  .banner .banner__project-5th {
    grid-row: 6/7;
    grid-column: 1/1;
    background-position: center 5px;
  }
  .banner .banner__project-6th {
    grid-row: 7/8;
    grid-column: 1/1;
  }
  .skills {
    padding: 100px 20px;
    height: 1040px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .skills h2 {
    font-size: 42px;
    margin-bottom: 40px;
  }
  .skills .skills__grid {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(auto auto);
  }
  .skills .skills__grid .skills__box:nth-child(2) {
    border-top: none;
    border-right: none;
  }
  .skills .skills__grid .skills__box:nth-child(even) {
    border-right: none;
  }
  .skills .skills__grid .skills__box:nth-child(odd) {
    border-left: none;
  }
  .portfolio__container {
    min-height: 1580px;
    max-height: -moz-fit-content;
    max-height: fit-content;
    padding: 40px 20px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .portfolio__container h2 {
    font-size: 30px;
  }
  .portfolio__container .portfolio__projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 492px);
    gap: 20px;
  }
  .portfolio__container .portfolio__buttons {
    width: 80%;
    height: 90px;
    flex-wrap: wrap;
    margin-bottom: 80px;
  }
  .contact__container {
    height: 900px;
    padding: 1rem 20px;
  }
  .contact__container .contact__info {
    grid-row: 1/2;
    grid-column: 1/3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .contact__container .contact__form {
    grid-row: 2/3;
    grid-column: 1/3;
  }
  .dropdown-box {
    width: 55%;
  }
}
@media (min-width: 769px) and (max-width: 1279px) {
  .nav__container {
    padding: 0px 30px;
  }
  .header__container {
    padding: 80px 30px;
  }
  .profile {
    padding: 0px 30px;
  }
  .skills {
    padding: 0px 30px;
  }
  .contact__container {
    padding: 100px 30px;
  }
  .portfolio__container {
    padding: 0px 30px;
  }
  .portfolio__container .portfolio__buttons {
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px 30px;
    flex-wrap: wrap;
  }
  .portfolio__container .portfolio__projects {
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 55px;
  }
  .portfolio__container .portfolio__projects .portfolio__project-box {
    height: -moz-fit-content;
    height: fit-content;
  }
  .portfolio__container .portfolio__projects .portfolio__project-box p {
    height: 150px;
    overflow-y: scroll;
  }
  .dropdown-box {
    width: 55%;
  }
}/*# sourceMappingURL=style.css.map */