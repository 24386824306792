// colors
$orange: #fd5226;
$black: #000000;
$white: #f2f2f2;
$blue: #0000cc;
$grey: #141414;

// portfolio icons
$light-purple: #9370db;
$light-orange: #ffae42;
$light-blue: #1e90ff;

// contact - colors
$github: #ba55d3;
$linkedin: #0000ff;
$instagram: #ff8c69;

// padding
$side-padding-xl: 15rem;
$side-padding-lg: 30px;
$side-padding-md: 5px;
$side-padding-sm: 20px;
