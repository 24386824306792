@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Syne&display=swap");
@import "./utils/_variables";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  font-family: "Open Sans", "Syne", sans-serif;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  color: black;
}

.main__container {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Syne", sans-serif;
}

p {
  font-family: "Open Sans", sans-serif;
}

// Nav
.nav__container {
  font-family: "Open Sans", sans-serif;
  background-color: $black;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 12vh;
  padding: 0 $side-padding-xl;
  text-transform: uppercase;
  font-size: 15px;

  h2 {
    font-size: 15px;
    letter-spacing: 1px;
  }

  button {
    display: none;
  }

  ul {
    display: flex;
    list-style: none;
    font-family: "syne", sans-serif;

    li {
      padding: 0 2rem;
    }

    #portfolio {
      color: $orange;
    }

    #portfolio:hover {
      font-weight: 700;
    }

    .link {
      color: $white;
      text-decoration: none;
      transition: color 0.3s, box-shadow 0.3s;
    }
    .link:hover {
      color: $orange;
    }
  }
}

// Contact
.input-fields {
  margin-bottom: 1rem;
  border: 3px solid $black;
  padding-left: 10px;
  font-family: "Open Sans", sans-serif;
  color: $black;
  font-weight: 700;
  letter-spacing: 1.2px;
}

.contact__container {
  height: 88vh;
  width: 100%;
  padding: $side-padding-xl;
  padding-top: 14rem;
  background-color: $white;
  color: $black;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 2fr 1fr;

  .contact__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      font-size: 3.5rem;
    }

    h3 {
      font-size: 1.4rem;
    }

    span {
      color: $orange;
      margin-right: 5px;
    }
  }

  .contact__form {
    form {
      display: flex;
      flex-direction: column;
    }

    input {
      @extend .input-fields;
      height: 3rem;
    }

    textarea {
      @extend .input-fields;
      height: 10rem;
      padding-top: 10px;
    }

    *::placeholder {
      font-size: 1rem;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
    }

    button {
      width: 9rem;
      height: 3.5rem;
      color: $black;
      font-size: 1.2rem;
      background-color: $orange;
      border: 2px solid $orange;
      border: none;
    }

    button:hover {
      border: 2px solid $orange;
      cursor: pointer;
      color: $orange;
      background-color: $white;
    }
  }

  .contact__developed {
    height: 5rem;
    margin-top: 10rem;
    border-top: 1px solid $black;
    padding-top: 1rem;
    grid-column: 1 / 3;
    display: flex;
    justify-content: space-between;

    h4 {
      color: $grey;

      span {
        color: $orange;
      }
    }

    ul {
      display: flex;
      list-style: none;
      width: 480px;
    }

    li {
      margin: 0 2rem;

      a {
        display: flex;
        width: fit-content;
        text-decoration: none;
      }

      button {
        background-color: transparent;
        border: none;
        font-size: 1.4rem;
        color: $black;
        margin-right: 5px;
      }

      &:first-child button:hover {
        color: $github;
      }

      &:nth-child(2) button:hover {
        color: $linkedin;
      }

      &:last-child button:hover {
        color: $instagram;
      }

      button:hover {
        cursor: pointer;
        color: $orange;
      }
    }
  }
}

// Header
.header__container {
  background-color: $black;
  color: $white;
  height: 36rem;
  width: 100%;
  padding: 2rem $side-padding-xl;

  h2 {
    font-size: 70px;
    margin-bottom: 4rem;
  }

  .header-link {
    background-color: $orange;
    width: 185px;
    height: 52px;
    padding: 15px 30px;
    font-size: 18px;
    text-decoration: none;
    color: $black;
    text-transform: uppercase;
    font-family: "Syne";
  }

  .header-link:hover {
    background-color: $black;
    border: 2px solid $orange;
    cursor: pointer;
    color: $orange;
  }
}

// Profile
.profile {
  width: 100%;
  height: 50rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5rem $side-padding-xl;
  background-color: $white;

  .profile__text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    h2 {
      font-size: 50px;
      width: 90%;
    }

    p {
      font-size: 15px;
      width: 82%;
    }

    .profile-link {
      width: 205px;
      height: 52px;
      font-size: 18px;
      text-align: center;
      background-color: $orange;
      text-decoration: none;
      color: $black;
      text-transform: uppercase;
      padding: 15px 30px;
    }

    .profile-link:hover {
      border: 2px solid $orange;
      background-color: $white;
      color: $orange;
    }
  }

  .profile__img {
    background-image: url(./assets/profile-img.svg);
    background-position: center 11rem;
    background-repeat: no-repeat;
  }
}

// Banner
.projects__general {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.banner {
  width: 100%;
  height: 50rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1.49fr 1.49fr;
  grid-template-rows: 1fr 1fr;
  background-color: $black;

  .banner__project-1st {
    @extend .projects__general;
    background-image: url(./assets/momentum.png);
    grid-row: 1 /2;
    grid-column: 1/3;
  }

  .banner__project-2nd {
    @extend .projects__general;
    background-image: url(./assets/weather.png);
    grid-row: 1 /3;
    grid-column: 3/4;
  }

  .banner__project-3rd {
    @extend .projects__general;
    background-image: url(./assets/todo.png);
    grid-row: 1 /2;
    grid-column: 4/5;
  }

  .banner__project-4th {
    @extend .projects__general;
    background-image: url(./assets/museum.png);
    grid-row: 2/3;
    grid-column: 1/2;
  }

  .banner__project-5th {
    @extend .projects__general;
    background-image: url(./assets/portfolio.png);
    grid-row: 2/3;
    grid-column: 2/3;
  }

  .banner__project-6th {
    @extend .projects__general;
    background-image: url(./assets/sunny.png);
    grid-row: 2/3;
    grid-column: 4/5;
  }
}

// Skills
.skills {
  width: 100%;
  height: 44rem;
  padding: 2rem $side-padding-xl;
  background-color: $black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: "Syne";

  h2 {
    text-align: center;
    font-size: 50px;
    margin-bottom: 1rem;
    color: $white;
  }

  .skills__grid {
    display: grid;
    grid-template-columns: repeat(4, 1.2fr);
    grid-template-rows: repeat(4, 1fr);

    .skills__box {
      border: 2px solid $orange;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $black;

      img {
        width: 50px;
        height: 50px;
      }

      h3 {
        color: $white;
      }
    }

    .skills__box:first-child {
      border-top: none;
      border-left: none;
    }
    .skills__box:nth-child(2),
    .skills__box:nth-child(3) {
      border-top: none;
    }

    .skills__box:nth-child(4) {
      border-top: none;
      border-right: none;
    }

    .skills__box:nth-child(5),
    .skills__box:nth-child(9) {
      border-left: none;
    }

    .skills__box:nth-child(8),
    .skills__box:nth-child(12) {
      border-right: none;
    }

    .skills__box:nth-child(13) {
      border-bottom: none;
      border-left: none;
    }

    .skills__box:nth-child(14),
    .skills__box:nth-child(15) {
      border-bottom: none;
    }

    .skills__box:nth-child(16) {
      border-right: none;
      bottom: none;
    }
  }
}

// Portfolio--------------------
.portfolio__container {
  width: 100%;
  height: fit-content;
  padding: 0 $side-padding-xl 100px;
  text-align: center;
  background-color: $black;

  h2 {
    color: $white;
    font-size: 75px;
    font-family: "syne";
    padding: 80px 0;
  }

  .portfolio__buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    button {
      font-size: 15px;
      font-family: "Syne";
      margin: 5px;
      padding: 8px 20px;
      height: 40px;
      width: fit-content;
      border-radius: 0;
      border: none;
      background-color: $white;
      cursor: pointer;
      text-transform: uppercase;
    }

    button:hover {
      background-color: $orange;
    }

    button:first-child {
      background-color: $orange;
    }

    button:active {
      background-color: yellow;
    }
  }

  .portfolio__projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 470px);
    gap: 0.8rem;

    .portfolio__project-box {
      border: 2px solid red;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border-radius: 2rem;
      padding: 0.8rem;
      border: 2px solid $orange;

      .project__preview {
        overflow-y: hidden;
        overflow-x: hidden;
        height: 133px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        img {
          flex-grow: 1;
          width: 100%;
          height: 100%;
          object-fit: cover;

          transition: all 0.3s ease-in-out;
        }

        img:hover {
          transform: scale(1.1);
        }
      }

      .project__title {
        font-size: 20px;
        color: $white;
      }

      p {
        color: $white;
      }

      .portfolio__icons {
        display: flex;
        justify-content: space-around;
        align-items: center;

        a {
          text-decoration: none;
          margin: auto 0;
          font-size: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $white;
          border: none;
          background-color: transparent;
          width: 20%;

          button {
            background-color: transparent;
            border: none;
          }

          h3 {
            margin-left: 5px;
          }

          #explorer {
            color: $light-blue;
          }

          #github {
            color: $github;
          }
        }
        a:first-child h3:hover {
          color: $light-blue;
        }
        a:nth-child(2) h3:hover {
          color: $github;
        }
      }

      .Momentum-clone {
        grid-row: 1/2;
        grid-column: 1/2;
      }

      .Todo {
        grid-row: 1/2;
        grid-column: 2/3;
      }

      .Sunny {
        grid-row: 1/2;
        grid-column: 3/4;
      }

      .Weather {
        grid-row: 2/4;
        grid-column: 1/2;
      }

      .Portfolio {
        grid-row: 2/4;
        grid-column: 3/4;
      }

      .Calculator {
        grid-row: 3/4;
        grid-column: 2/3;
      }
    }
  }
}

// DropDownBox
.dropdown-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dropdown-box {
  width: 30%;
  background: $white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: dropDown 0.3s ease-out;

  .dropdown-close {
    display: flex;
    justify-content: end;

    button {
      color: $black;
      background-color: transparent;
      cursor: pointer;
      border: none;
      padding: 8px 14px;
      border-radius: 8px;
    }

    button:hover {
      background: $orange;
    }
  }

  a {
    text-decoration: none;
    background-color: $orange;
    color: $grey;
    border-radius: 8px;
    padding: 5px;
  }
}

.dropdown-box p {
  font-weight: 500;
  margin: 0 0 25px;
  color: $grey;
}

@keyframes dropDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
// -----------------------------

// media queries -----------
@media (max-width: 480px) {
  .nav__container {
    padding: 0px;
    flex-direction: column;
    height: fit-content;
    padding-bottom: 5px;

    .nav__text {
      padding: $side-padding-sm;
      display: flex;
      justify-content: space-between;
      width: 100%;

      button {
        border: none;
        font-size: 20px;
        background: $white;
        color: $black;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .showNav-active {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: fit-content;

      li {
        border-top: 2px solid $white;
        border-bottom: 2px solid $white;
        padding: 20px;
        font-size: 15px;
        min-height: 80px;
        display: flex;
        align-items: center;
      }
    }

    .showNav-disable {
      display: none;
    }
  }

  .header__container {
    padding: 40px $side-padding-sm 100px;
    height: 360px;
    h2 {
      font-size: 30px;
    }
    .header-link {
      width: 144px;
      height: 46px;
      padding: 12px 24px;
      font-weight: bold;
    }
  }

  .profile {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    padding: 60px $side-padding-sm 100px;
    height: 955px;

    .profile__img {
      background-position: center 5rem;
      grid-row: 1/2;
      background-size: 80%;
    }

    .profile__text {
      grid-row: 2/3;
      text-align: center;
      margin: 0 auto;
      h2 {
        font-size: 28px;
        width: 100%;
      }

      p {
        font-size: 15px;
        width: 100%;
      }

      .profile-link {
        width: 193px;
        height: 46px;
        padding: 12px 24px;
        margin: 0 auto;
      }
    }
  }

  .banner {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 200px);
    height: 1400px;

    .banner__project-1st {
      grid-row: 1 /2;
      grid-column: 1/1;
    }

    .banner__project-2nd {
      grid-row: 4/6;
      grid-column: 1/1;
    }

    .banner__project-3rd {
      grid-row: 2/3;
      grid-column: 1/1;
    }

    .banner__project-4th {
      grid-row: 3/4;
      grid-column: 1/1;
    }

    .banner__project-5th {
      grid-row: 6/7;
      grid-column: 1/1;
      background-position: center 5px;
    }

    .banner__project-6th {
      grid-row: 7/8;
      grid-column: 1/1;
    }
  }

  .skills {
    padding: 100px $side-padding-sm;
    height: 1040px;

    h2 {
      font-size: 28px;
    }

    .skills__grid {
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat();

      .skills__box:nth-child(2) {
        border-top: none;
        border-right: none;
      }
      .skills__box:nth-child(even) {
        border-right: none;
      }
      .skills__box:nth-child(odd) {
        border-left: none;
      }
    }
  }

  .contact__container {
    height: 800px;
    padding: 100px $side-padding-sm 80px;
    grid-template-columns: 1fr;
    grid-template-rows: 290px 390px 100px;

    .contact__info {
      height: 290px;
      h2 {
        font-size: 28px;
      }
      h3 {
        font-size: 18px;
      }
    }
    .contact__developed {
      margin-top: 20px;

      li {
        margin: 0 10px;
      }
    }
  }

  // portfolio -----
  .portfolio__container {
    height: fit-content;
    padding: 40px $side-padding-sm 40px;

    h2 {
      font-size: 30px;
    }

    .portfolio__projects {
      display: flex;
      flex-direction: column;

      .portfolio__project-box {
        height: fit-content;
      }
    }

    .portfolio__buttons {
      padding: 40px $side-padding-sm 40px;
      width: 370px;
      height: fit-content;
      flex-wrap: wrap;
      margin-bottom: 80px;
    }
    .portfolio__projects {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(8, 190px);
      gap: 25px;
      margin: 0 auto;
      width: 85%;
    }
  }

  .contact__container {
    padding: 1rem $side-padding-sm;
    .contact__info {
      grid-row: 1/2;
      grid-column: 1/3;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .contact__form {
      grid-row: 2 / 3;
      grid-column: 1/3;
    }
  }

  // DropDown
  .dropdown-box {
    width: 85%;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .nav__container {
    padding: 0px;
    flex-direction: column;
    height: fit-content;
    padding-bottom: 5px;

    .nav__text {
      padding: $side-padding-sm;
      display: flex;
      justify-content: space-between;

      width: 100%;

      button {
        border: none;
        font-size: 20px;
        background: $white;
        color: $black;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .showNav-active {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: fit-content;

      li {
        border-top: 2px solid $white;
        border-bottom: 2px solid $white;
        padding: 20px;
        font-size: 15px;
        min-height: 80px;
        display: flex;
        align-items: center;
      }
    }

    .showNav-disable {
      display: none;
    }
  }

  .header__container {
    padding: 40px $side-padding-sm 100px;
    height: 450px;
    h2 {
      font-size: 50px;
    }
    .header-link {
      width: 144px;
      height: 46px;
      padding: 12px 24px;
      font-weight: bold;
    }
  }

  .profile {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    padding: 60px $side-padding-sm 100px;
    height: 955px;

    .profile__img {
      background-position: center 5rem;
      grid-row: 1/2;
      background-size: 80%;
    }

    .profile__text {
      grid-row: 2/3;
      text-align: center;
      margin: 0 auto;
      h2 {
        font-size: 42px;
        width: 100%;
      }

      p {
        font-size: 15px;
        width: 100%;
      }

      .profile-link {
        width: 193px;
        height: 46px;
        padding: 12px 24px;
        margin: 0 auto;
      }
    }
  }

  .banner {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 200px);
    height: 1400px;

    .banner__project-1st {
      grid-row: 1 /2;
      grid-column: 1/1;
    }

    .banner__project-2nd {
      grid-row: 4/6;
      grid-column: 1/1;
    }

    .banner__project-3rd {
      grid-row: 2/3;
      grid-column: 1/1;
    }

    .banner__project-4th {
      grid-row: 3/4;
      grid-column: 1/1;
    }

    .banner__project-5th {
      grid-row: 6/7;
      grid-column: 1/1;
      background-position: center 5px;
    }

    .banner__project-6th {
      grid-row: 7/8;
      grid-column: 1/1;
    }
  }

  .skills {
    padding: 100px $side-padding-sm;
    height: 1040px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 42px;
      margin-bottom: 40px;
    }

    .skills__grid {
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(auto auto);

      .skills__box:nth-child(2) {
        border-top: none;
        border-right: none;
      }
      .skills__box:nth-child(even) {
        border-right: none;
      }
      .skills__box:nth-child(odd) {
        border-left: none;
      }
    }
  }

  // portfolio----
  .portfolio__container {
    min-height: 1580px;
    max-height: fit-content;
    padding: 40px $side-padding-sm 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 30px;
    }

    .portfolio__projects {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 492px);
      gap: 20px;
    }

    .portfolio__buttons {
      width: 80%;
      height: 90px;
      flex-wrap: wrap;
      margin-bottom: 80px;
    }
  }

  .contact__container {
    height: 900px;
    padding: 1rem $side-padding-sm;
    .contact__info {
      grid-row: 1/2;
      grid-column: 1/3;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .contact__form {
      grid-row: 2 / 3;
      grid-column: 1/3;
    }
  }

  .dropdown-box {
    width: 55%;
  }
}

@media (min-width: 769px) and (max-width: 1279px) {
  // nav
  .nav__container {
    padding: 0px $side-padding-lg;
  }

  // header
  .header__container {
    padding: 80px $side-padding-lg;
  }

  // profile
  .profile {
    padding: 0px $side-padding-lg;
  }

  // skills
  .skills {
    padding: 0px $side-padding-lg;
  }

  // contact
  .contact__container {
    padding: 100px $side-padding-lg;
  }

  .portfolio__container {
    padding: 0px $side-padding-lg;

    .portfolio__buttons {
      height: fit-content;
      padding: 0px $side-padding-lg;
      flex-wrap: wrap;
    }

    .portfolio__projects {
      height: fit-content;
      padding-bottom: 55px;

      .portfolio__project-box {
        height: fit-content;

        p {
          height: 150px;
          overflow-y: scroll;
        }
      }
    }
  }

  .dropdown-box {
    width: 55%;
  }
}
